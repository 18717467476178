export const mg = {
  tooltip: "Iangaviana ianao hamita ny andininy voalohany",
  home: {
    head: "Tongasoa eto amin'ny ivo toeram-piofanana TERAKA",
    description: "Maimaim-poana ny tranonkala ary tsy misy adidy",
  },
  login: {
    titre: ["Hiditra", "Hamorona Kaonty", "Adino ny teny miafina"],
    label: [
      "anarana",
      "fanampiny",
      "Kaonty",
      "Teny miafina",
      "Famerenana teny miafina",
      "Adiresy mailaka",
      "Finday",
      "Fiteny",
      "Firenena",
      "Faritra",
      "Kaominina",
      "Asanao ao amin'ny TERAKA ?",
      "Anaty Vondrona Madinika ve ?",
      "Laharana vondrona madinika ?",
      "Manaiky ny fepetra mifehy ny tranonkala",
      "Fomba hamerenana",
      "Alefa mailaka",
      "Hamaly fanontaniana",
      "Vita",
      "Teny miafina vaovao",
    ],
  },
  button: [
    "Programa",
    "Hiditra",
    "Kaonty",
    "Teny",
    "F A Q",
    "Hivoaka",
    "Hiditra",
    "Hamorona",
    "Teny miafina adino",
    "Tadiavo",
    "Alefa",
    "Hijery",
    "Tohiny",
    "Tapitra",
    "Hiverina",
  ],
  alert: [
    "Tongasoa indray",
    "Arabaina nahavita lesona",
    "Efa vitanao ito lesona ito",
    "Tsy maintsy fenoina",
    "Litera 8 farafahakeliny ny teny miafina",
    "Tsy mitovy ny teny miafina",
    "Ekeo ny fepetra",
  ],
  label: "Fiofanana tsy mila internet",
  question: [
    "Iza ny anaran'ny alikanao?",
    "Taiza ny tanana nahaterahanao?",
    "Aiza ianao no te hipetraka?",
    "Inona ny film tinao indrindra?",
  ],
  cours: [
    {
      titre: "Ahoana ny fampiasana ny tranonkala fianarana",
      descri:
        "Ity fiofanana ity dia mampiseho amin'ny olona ny fomba fampiasana ny TERAKA Learning Center, ao anatin'izany ny fifindrana avy amin'ny fiofanana iray mankany amin'ny manaraka. Toerana tsara hanombohana izany ao amin'ny TERAKA Learning Center. Rehefa vita io fiofanana io dia afaka miverina amin'ny katalaogin'ny taranja ianao. ary mifidiana taranja hafa hanombohana.",
      liste: [
        {
          titre:
            "Mianara eto: Ahoana ny fampiasana ny tranonkala fanofanana TERAKA",
        },
        {
          titre: "Mianara eto: Ahoana ny fangalana PDF fiofanana TERAKA",
        },
      ],
    },
    {
      titre: "Fampidirana ho an'ny Mpamboly Kandidà TERAKA",
      descri:
        "ty fiofanana ity dia mirakitra fampianarana momba ny fampandehanana ny TERAKA, fiofanana ilaina alohan’ny hidirana amin’ny toko manaraka. Mba hanombohana tsara ny maha-mpikambana TERAKA dia tsara hanombohana ity fiofanana ity. Misy fizarana fito (7) tanteraka. tsy miankina amin'ny tsirairay, dia tsara kokoa ny manaraka ny fiofanana araka ny filaharan'ny zana-toko.",
      liste: [
        {
          titre: "Soatoavina fototra TERAKA",
        },
        {
          titre: "Mamboly hazo sy TERAKA",
        },
        {
          titre: "Fambolena hazo sy crédit carbone",
        },
        {
          titre: "Ny firafitry ny Vondrona madinika TERAKA",
        },
        {
          titre: "Ahoana ny fomba hahatongavana ho vondrona kely TERAKA",
        },
        {
          titre: "Fahazoana ny fandoavam-bola sy ny fifanarahana GES TERAKA",
        },
        {
          titre: "Hampandroso an'i TERAKA",
        },
      ],
    },
  ],
  c101: [
    "Ireo karazana fifamoivoizana ivelan'ny lesona",
    "1. Top bar:",
    "Ity no ahafahanao mivezivezy ato anatin'ny tranonkala na ihany koa handeha hijery ireo tranonkala TERAKA hafa. Azonao kitihana ny iray amin'ireo raha te hifindra tranonkala TERAKA hafa ianao.",
    "Lalam-pifandraisan'ny tranonkala",
    "2. Chemin d'accès:",
    "Ity no ahafahanao mamantatra ny toerana misy anao, ny lalana nidiranao rehetra ka hatreo amin'ny misy anao izao. Azonao atao ny lalana raha te hiverina amin'ny toerana iray.",
    "Lalana naleha sy ny misy anao",
    "3. Compte:",
    'Ity no ahafahanao mijery ireo lesona tianao, na ihany koa, mivoaka ny Tranonkala "Déconnexion". Azonao atao ny mikitika ny iray amin ireo.',
    "Kaonty TERAKA",
    "4. Alert:",
    "Ity no ahafahan'ny pejy mifandray aminao. Ahitanao ireo teny madinika izy ito, miankina amin'ny lokony ny dikany.",
    "Notification",
    "Ireo karazana fifamoivoizana anatin'ny lesona",
    "1. Affichage:",
    "Ity no ahafahanao manova ny fisehon'ny lisitra ny lesona. Azonao kitihana ny iray amin'ireo raha te hiova fampisehoana ny lisitry ny lesona ianao.",
    "Fanovana endriky ny lisitra",
    "2. Affichage en grille:",
    "Toy izao ny fomba fiseho raha En grille ny safidinao. Izy ihany koa no miseho voalohany raha mbola tsy nisafidy ny hiova ianao. Azonao atao ny mikitika ny lesona iray raha te hanohy ny fianarana ao aminy ianao.",
    "Lisitra en Grille",
    "3. Affichage en ligne:",
    "Toy izao ny fomba fiseho raha En ligne ny safidinao. Azonao atao ny mikitika ny lesona iray raha te hanohy ny fianarana ao aminy ianao.",
    "Lisitra en Ligne",
    "4. Navigation:",
    "Ireto ny bokotra bouton ahafahanao mamaky ny lesona. Rehefa manindry ny tohiny ianao dia miakatra ny taha-pahavitanao ny lesona.",
    "Bokotra ao anatin'ny lesona",
    "5. Terminaison:",
    "Ireto ny bokotra famaranana bouton de terminaison ahafahanao mamarana ny lesona sy manome naoty arakarakin'ny fahitanao ny lesona. Rehefa manindry ny Tapitra ianao dia feno ny taha-pahavitanao ny lesona.",
    "Bokotra famaranana ny lesona",
    "6. Chapitre:",
    "Ito ny mampiseho ny mombamomban'ny chapitre iray. Hita ato ireo lesona mandrakotra ny chapitre ary ihany koa ny lesona efa vitanao. Maitso ny lokon'ny lesona raha vita 100% izy, manga kosa raha toa ka mbola misy tsy vita. Azonao kitihana ny lesona iray raha te hianatra azy ianao.",
    "Mombamomban'ny chapitre iray",
  ],
  c102: [
    "Aona ny fomba hangalana ny fiofanana?",
    "Ao anatin'ny fiofanana iray dia ahitana ny lisitry ny fichiers azo alaina",
    "Kitiho eo amin'iray amin'izy ireo raha te haka azy",
    "Ahitana an'ireo Lisitry ny fiofanana",
  ],
  c201: [
    "IZA MOA TERAKA ?",
    "TERAKA dia tantsaha mirotsaka an-tsitrapo hiditra ao amin'ny vondrom-piarahamonin’ny TERAKA. Tia fanabeazana izy ireo, tia mandrato fahalalana momba ny fiompiana amam-pambolena, ary tia mikolokolo ny tontolo iainana. Maro ireo tantsahan’ny TERAKA izay monina any amin'ny faritra maina, izay mijaly noho ny faharipahan’ny ala, ny fahapotehan'ny tany, ny hain-tany ary ny tsy fahampian-tsakafo. Ny TERAKA dia ahitana Vondrona Madinika. Miara-miasa izahay hamolavola fampiharana tsara sy hanatsara ny fambolena amam-piompiana ary ny tontolo iainanay.",
    "SOATOAVINA TERAKA",
    "Mandeha amin’ny rariny izahay.",
    "Manao zavatra mazava tsara izahay.",
    "Mangarahara izahay.",
    "Izahay dia tompon'andraikitra.",
    "Izahay dia mifampitondra ami-pifanompoana.",
    "Mpilatsaka an-tsitra-po izahay.",
    "Mikajy vokatra lehibe miaraka amin’ny teti-bola mora izahay.",
    "INONA NO MAMPIAVAKA AZY ?",
    "Mpilatsaka antsitra-po izahay. Ny tantsaha TERAKA dia mamolavola sy mizara fampahalalana momba ny fambolena maharitra, momban’ny ala, ny fahasalamana, ny resaka sakafo mahavelona ary ireo hetsika amin’ny fampandrosoana lovian-jafy. Izahay dia mizara ireo fampiharana tsara amin'ireo Vondrona Madinika rehetra.",
    "INONA NO ATAONTSIKA ?",
    "MAMPANIRY HAZO",
    "Rehefa mihalehibe ny hazo dia mampihena ny gazy karbaonika amin'ny rivotra ny photosynthèse amin'ny alàlan'ny fitahirizana azy ao anaty hazo, ny fakany ary ny tany. Ny mpiara-miasa aminay, iTERAKA, dia manao izay rehetra azo atao mba hamadika ity karbaona ity ho kredi karbaona ary avy eo mivarotra azy eny amin'ny tsenan'ny karbaona. Ny tantsahan’ny TERAKA kosa dia mahazo fiomanana isan-taona ary hahazo 70% ny tombom-barotra amin'ny fivarotana ny kredi karbaona.",
    "MANOFANA MPITARIKA",
    "Mampiofana mpitarika ihany koa izahay. Amin'ny alàlan'ny “fifandimbiasan’ny fitarihina”, ny tantsaha TERAKA rehetra dia mianatra mitarika ny Vondrona Madinika ananany.",
    "MIZARA FAMPIHARANA TSARA ",
    "Mamolavola sy mizara fomba fanao tsara indrindra momba ny fambolena maharitra, ny ala, ny fahasalamana ( ao anatin'izany ny VIH / SIDA ), ary ny hetsika amin’ny fampandrosoana lovain-jafy.  Ny tantsaha TERAKA dia mizara ireo fampiharana tsara amin'ireo vondrona madinika rehetra.  Manana fampitam-baovao, fivorian’ny kilasitera isam-bolana isika, ary Seminera isam-paritra izay mizara ny fampiharana tsara novolavolainay.",
    "INONA NO FORONINAY ?",
    "Misehatra amin’ny fandraharahana amin’ny karbaona izahay.",
    "Mandraharaha ao amin’ny sehatry ny karbaona ny tantsahan’ny TERAKA mba hanohanana ny fianakaviany ary hanatsarana ny toeram-pambolena sy ny vondrom-piarahamonina misy azy ireo ary ny planeta.",
    "Mamorona kredi karbaona izahay",
    "Manome vola ny tenantsika sy ny ezaka ataontsika isika amin'ny fandrefesana tsara ny habetsaky ny gazy karbaonika teny amin’ny habakabaka notrohan’ny hazo izay nambolentsika eto amintsika.",
    "Mamorona fidiram-bola vaovao hoan’ny tokantrano isika.",
    "Miara-miasa amin'ny iTERAKA mba handrefesana ny vokatra azontsika amin'ny fomba mazava sy mangarahara isika mba hahafahantsika miditra amin'ny tsenan'ny karbona manerantany.",
    "Mampiasa teti-bola mora izahay mba amoronana vokatra lehibe.",
    "TERAKA sy iTERAKA dia niasa mafy nandritra ny 20 taona mahery mba hampihenana ny vidin'ny fandaharana mba hahafahan'ny vola karbonina bebe kokoa mankany amin'ny tantsaha TERAKA.",
    "FA MANINONA NO MANAN-DANJA NY SOATOAVIN’NY TERAKA ?",
    "Mampisy fifampitokisana izahay",
    "TERAKA dia miasa miaraka amin’ireo azo itokisana ihany: mivarotra crédit karbaona, izay tsy hit any maso, tsy voakasiky ny tanana, ary tsy afaka andramana akory isika. Ny orinasa mividy ny kredi karbaona dia tsy maintsy matoky ny TERAKA, ary ny mpikambana ao amin’ny TERAKA dia tsy maintsy mifampatoky.",
    "Mifanampy mba ahazahoana tombony izy ireo.",
    "Ny soatoavina TERAKA dia manampy ny tantsaha hahazo valiny lehibe. Amin'ny alàlan'ny fiasa miaraka amin'ny teti-bola ambany izay miteraka valiny lehibe, dia mamorona vola karbonina bebe kokoa ho an'ny tantsaha TERAKA izahay.",
    "Avy amin’ny tantsaha, hoan’ny tantsaha.",
    "TERAKA dia ataon'ireo tantsaha, ho an'ny tantsaha. Amin'ny fanarahana ny soatoavina TERAKA dia miara-miasa isika sy ny tenantsika. Ireo soatoavina ireo dia manohana ny fiaraha-monina sy ny fandraisana andraikitra.",
    "Mifanompo.",
    "Ny soatoavin’ny TERAKA dia mamnampy anay hifanompo.",
  ],
  c202: [
    "IREO TOMBONTSOA AZO AVY AMIN’NY FAMBOLEN-KAZO",
    "MANARINA AN’IREO TOERANA VOATRANDRADRAKA NY FAMBOLEN-KAZO",
    "Ny fambolen-kazo dia miaro ny tany tsy hovoakaokan’ny riaka ary miaro amin’ny fihotsahan’ny tany.",
    "NY HAZO DIA MANOME VOANKAZO SY VOANJO IZAY AZO ANAMPIANA NY FIDIRAM-BOLA SY NY FOMBA FISAKAFOANANA",
    "Karazan-javamaniry maro no mamoa voankazo, toy ny zavoka sy manga, na voanjo, toy ny macadamia. Ireo dia azo amidy soamantsara na ihany koa azo atao sakafo hoan’ny fianakaviana.",
    "NY HAZO SASANY DIA MANOME FANAFODY.",
    "Ny karazana hazo sasany, toy ny roy na ny marompototra, dia misy fanafody mahasoa.",
    "MIDIKA INONA IZANY HOE MAMBOLY HAZO MIARAKA AMIN’NY TERAKA ?",
    "Tsy maintsy 2 metatra ny halavan’ny hazo farafahakeliny ",
    "1 taona - 60 taona",
    "2 metatra",
    "NB:",
    "Afaka mamboly hazo mielanelana latsaky ny 2 metatra ireo tantsaha toy ny hazon’angovo. Saingy tsy mahazo vola karbaona ireo hazo ireo amin’izay fotoana izay.",
  ],
  c203: [
    "INONA NY ATAO HOE KARBAONA ?",
    "Eo amin'ny manodidina antsika ny karbôna, nefa tsy afaka mahita azy, na mikasika azy, na manandrana azy isika - inona moa izany ?",
    "Ny hazo manaloka antsika dia vita amin'ny karbaona",
    "Ny biby ompiantsika dia vita amin'ny karbaona",
    "Ny legioma ambolentsika dia vita amin'ny karbaona",
    "Ny solika ampiasaintsika isan'andro dia vita amin'ny karbaona koa: solika toy ny lasantsy, petrola, ary kitay fandrahoana sakafo",
    "Rehefa mandoro solika mifototra amin'ny karbaona isika, dia mamorona setroka mandoto ny rivotra iainana izany ary mamadika ny tafo fanitso ho mainty.",
    "INONA NY TSINGERIN’NY KARBAONA ?",
    "1ère dingana :",
    "Ny karbaona dia hita eny rehetra eny amin'ny tany. Ny karbaona no mandrindra ny mari-pana ny tany, no fototry ny tondra-drano rehetra, ary loharanon-tsolika lehibe. Ny karbaona no fototry ny zava-manan’aina rehetra eto an-tany. Ny karbaona dia hita ao amin'ny atmosfera amin'ny endrika gazy karbonika na CO2. Tsy hitan’ny maso ny karbaona, na fatatra tsiro, na rem-pofona, fa manodidina antsika.",
    "2ème dingana :",
    "Ny hain-tany lavareny sy ny fiakaran’ny hafanana dia samy vokatry ny fiovaovan'ny toetr'andro. Saingy misy zavatra tsotra azontsika rehetra atao mba hiadiana amin'ny antony mahatonga ny fiovaovan'ny toetr'andro: Mamboly hazo: ny hazo dia mitroka ny CO2 avy amin'ny atmosfera rehefa mitombo izy ireo.",
    "TERAKA SY NY FANDRAHARAHANA AMIN’NY SEHATRY NY KREDI KARBAONA ?",
    "Ny orinasam-bola karbônina dia ny fomba handraisan'ny iTERAKA vola amin'ny fampandehanana ny programa TERAKA",
    "Ny TERAKA Farmers dia manatevin-daharana vondrona kely miaraka amin'ny tanjona hanampiana ny tontolo iainana",
    "Ny Vondrona Madiniky ny TERAKA dia manao sonia fifanarahana amin'ny iTERAKA, ilay orinasa mitantana ny Programa TERAKA",
    "Io fifanarahana io dia antsoina hoe Fifanarahana amin’ny Entona Mangeja Hafanana na Fifanarahana EMH",
    "Kojakojain’ny Vondrona Madinika ao amin’ny TERAKA ho velona tsara ireo hazo.",
    "Ho avy hanisa ny hazo sy handrefy ny fitomboan'ny hazo ny kilasiteran’ny TERAKA",
    "Izany dia ahafahan'ny iTERAKA mikajy ny habetsahan'ny karbaona voaangon’ny hazo tamin'ny rivotra",
    "Ny iTERAKA dia manakarama mpanamarina mba hahazoana antoka fa marina io fandrefesana io",
    "Ny iTERAKA dia mamorona kredi karbaona avy amin'ny habetsahan'ny karbaona izay voaangon’ ireo hazon’ny TERAKA teny amin'ny rivotra",
    "Ny orinasa dia mividy ity kredi karbaona ity amin'ny iTERAKA",
    "Dia izay sy izay no tombontsoa azon’ny Vondrona Madinika ao amin’ny TERAKA amin’ny alalan’ny fandraharahana ao amin’ny karbaona.",
    "FANAMARIHANA :",
    "Fandoavana mialoha ny karbônina",
    "Tany am-piandohana dia ho kely loatra ny hazo TERAKA mba hamoronana crédit karbonika. Mandra-pahalehiben'ny hazo TERAKA dia hahazo fandoavana 0,02 euro isaky ny hazo isan-taona ny Vondrona Madinika TERAKA mba hitazonana ilay hazo hitombo.",
    "Fizarana ny tombon’ny karbaona ",
    "Rehefa lehibe ny hazo ary mahafeno ny fepetra rehetra azo atao ny Vondrona Madinika, dia hahazo anjara tombony ny Vondrona Madinika an’i TERAKA. Ny fizaran’ny tombony dia noforonina rehefa mivarotra ny kredi karbaona amin'ny olona na orinasa hafa ny iTERAKA, ary ny iTERAKA dia mizara ny iTERAKA 70% amin’ny tombom-barotra azony amin’ny tantsahan’ny TERAKA.",
    "Famoronana kredi karbaona",
    "Mba hamoronana ny kredi karbaona, ny Kilasiteran’ny TERAKA dia hanisa ny hazo isaky ny tanin-kazo, handrefy ny manodidina ny vatan-kazo, ary hanoratra ny karazan- kazo, ary ny hijery ny elanelan’ny hazo novolena. Ireo rehetra ireo dia ampidirina amin'ny tahirin-kevitr’I TERAKA avokoa. Ary ampiasain’ny iTERAKA ireo information rehetra ireo mba hamoronana ny kredi karbaona. Ity kredi karbaona ity dia amidy amin'ny olona na orinasa hafa te hanampy ny tontolo iainana.",
    "IREO TOMBON-TSOAN’NY TERAKA HAFA",
    "Fatana fandrahoana tsara kalitao",
    "Fambolena maharitra",
    "Fiofanana amin’ny fanaovana kompaosita zezi-pahitra",
    "Manampy ireo tantsaha amin’ny fahazahoana vokatra miavosa izany, ary manampy ny tontolo iainana, ary amin’izay ihany koa mba tsy mila mividy zezika.",
    "Fahasalamana",
    "Ny fahasalaman’ny tantsaha no voalohan-karena. Mahazo fiofanana amin’ny fikajiana ny fahasalamana ireo mpambolin’ny TERAKA, miofana ihany koa momban’ireo karazana olana ara-pahasalamana matetika sedrain’ny olona",
    "Fampiroboroboana ny haitarika",
    "Ireo tantsahan’ny teraka rehetra dia manana anjara ho mpitarika sy hisitraka fiofanana ho mpitarika avokoa.",
  ],
  c204: [
    "AHOANA NO ANANGANANA VONDRONA MADINIKA MATANJAKA ?",
    "Vondrona madinika",
    "Ny Vondrona Madinina an’i TERAKA dia ahitana mpikambana 6 ka hatramin’ny 12 izay avy amin'ny fianakaviana 3 farafahakeliny ary samy mifanakaiky trano.",
    "Mila manana Vondrona Madinika ianao raha te hiditra ao amin’ny fianakaviam-ben’ny TERAKA. Mila fieritreretana tsara ny fananganana Vondrona Mandinika. Ny Vondrona Madinika dia ahitana namana sy mpifanolo-bodirindrina, ary ahafahan'ny mpikambana TERAKA hamptombo ny trai-kefa amin'ny maha-mpitarika azy izany. Ny Vondrona Madinika TERAKA manara-penitra dia misy olona miasa mafy ary mametraka ny Soatoavin’ny TERAKA. Matetika ny lehilahy sy ny vehivavy ao amin'ny Vondrona Madinika iray no mahavita be indrindra.",
    "NY FAMPANANTENANA’NY VONDRONA MADINIK’I TERAKA",
    "Raha toa ka manantevindanharana ao amin’ny TERAKA ny Vondrona Madinika iray, dia manaiky an’izao ny mpikambana ao aminy:",
    "Manolo-tena hamboly hazo 1000 farafahakeliny isan-taona mandritra ny 5 taona",
    "Mihaona in-1 isan-kerinandro farafahakeliny ",
    "Manao sonian’ny fifanarahana EMH",
    "Mampihatra ny soatoavin’ny TERAKA ",
    "Mampihatra ny fifandimbiasan’ny fitarihina sy ny fifanorenana",
    "ANJARA ASAM-PITARIHAN’NY VONDRONA MADINIKA",
    "Ny Mpitarika dia mitarika am-mpanompoana ny Vondrona Madinika, mitarika ny fivoriana ary manao izay ahafahan'ny tsirairay mandray anjara.",
    "Mpitarika",
    "Ny mpiara-mitarika na mpitarika mpanampy dia manampy amin'ny fitarihana ny Vondrona Madinika ary manampy amin’ny fikajiana fotoana. Ny Mpitarika mpanampy no lasa Mpitarika amin’ny fivoriana manaraka",
    "Mpiara-mitarika",
    "Mampahatsiahy ny Vondrona Madinika ny andraikiny sy ny fanoloran-tenany teo aloha sy ankehitriny ny olona tompon’nadraikitra. Izy io dia lasa mpitarika mpanampy amin’ny fivoriana manaraka.",
    "Olona tompon'andraikitra",
    "FIFANDIMBIASAN’NY FITARIHINA",
    "Ny fifandimbiasan’ny fitarihina dia anisan’ny fampiharana tsaran’ny TERAKA ho an'ny vondrona madinika. Mifandimby isaky ny fivoriana (isan-kerinandro) ny fitarihana, ka ny Mpitarika mpanampy no lasa Mpitarika, ny Tompon'andraikitra lasa Mpitarika mpanampy, ary ny olona tompon'andraikitra vaovao no hofidiana.",
    "Ny fitarihana dia mifandimby eo amin'ny lehilahy sy ny vehivavy isaky ny ambaratongan'ny TERAKA. Midika izany fa raha lehilahy ny olona tompon'andraikitra amin'izao fotoana izao dia tokony ho vehivavy ny manaraka. Araka izany dia mifandimby hatrany ny fitarihana ny lehilahy sy ny vehivavy",
    "TOMBONTSOA AZO AVY AMIN’NY FIFANDIMBIASAN’NY FITARIHINA",
    "Ny olona tsirairay dia manana fahafahana hitarika am-panompoana",
    "Misy fahafahana mitovy ho an'ny lehilahy sy ny vehivavy.",
    "Mifampianatra momba ny maha-mpitarika tsara avy amin’ny tsirairay isika.",
    "Ny fifandimbiasan’ny fitarihina dia manome antoka fa misy hevitra vaovao sy karazana fitarihana vaovao ampiasaina isaky ny Vondrona Madinika",
    "KUJENGANA",
    "Inona ny Kujengana",
    "Ny Kujengana dia teny Soahily izay midika “fifanorenana”, ary zavatra tsy maintsy atao isaky ny avy mivory ny Vondrona Madinika.",
    "Ahoana ny fampiharana ny Kujengana ?",
    "Mialoha ny vavaka famaranana, ny mpikambana ao amin'ny Vondrona Madinika dia milaza zavatra iray manokana sy tsara izay nataon'ny Mpitarika nandritra ny fivoriana. Ireo fanehoan-kevitra ireo dia tokony ho voafaritra tsara ary manondro zavatra nataon’ny Mpitarika na nolazaina. Dinio tsara izany zavatra izany. Tsy tokony ho fanehoan-kevitra ankapobe toy ny hoe “nanao asa tsara ny Mpitarika”. Tokony ho fanehoan-kevitra manokana toy ny hoe “ny Mpitarika dia nitana fotoana, nitsiky, ary namporisika ny mpikambana ao amin’ny Vondrona Madinika rehetra hiteny”.",
    "Fa maninona no mampihatra ny Kujengana ?",
    "Samy manana ny mampiavaka azy amin’ny talenta sy ny fanomezam-pahasoavana ny mpikamban’ny TERAKA tsirairay ary manana zavatra manan-danja ho fandraisana anajara amin’izany. Kujengana dia fampiharana ny filazana ny lanjan-javatra iray ho ren’ny olona rehetra. Toy ny fifandimbiasan’ny fitarihana, ny Kujengana dia zava-dehibe amin’ny fampiroboroboana ny haitarika ao amin’ny TERAKA.",
    "Tombontsoan’ny Kujengana",
    "Manome fahafalim-po hoan’ny mpitarika izany rehefa nahatontosa zavatra tsara izy",
    "Manampy ny mpitarika manaraka izany amin’ny fomba itondrany ny fivoriana manaraka",
    "Mampirisika ny olona ho lasa mpitarika ny Kujengana",
    "KILASITERAN’NY TERAKA",
    "Ny Vondrona Madinika tsirairay avy dia ao anatin’ny Kilasiteran’ny Vondrona Madinika. Ny kilasitera iray dia misy Vondrona Madinika 30 hatramin’ny 50 izay tsy dia mifanalavitra toeram-ponenana.",
    "Ahoana no fomba ananganana Kilasitera ?",
    "Ny Kilasitera tsirairay avy dia tokony manana Vondrona Madinika 30 katramin’ny 50 isa, izay:",
    "Mamboly hazo 200.000 isa farafahakeliny(izany hoe, mamboly hazo 1.000 isa-taona isam-bondrona)",
    "Manana pepiniera sy mikirakira zana-kazo",
    "Manana tany eo amin’ny 80 – 100 hektara eo hambolena hazo",
    "FIVORIAN’NY KILASITERA",
    "Mandefa solon-tena 2 (lahy iray sy vavy iray) any amin’ny fivorian’ny kilasitera isam-bolana ny Vondrona Madinikan’ny TERAKA. Misy agent kilasitera mandrindra an’ireo fivoriana ireo ary kendrena mba atao any amin’ny toerana mora hit any fihaonana. Mandritran’ny fivoriana, ny Tantsahan’ny TERAKA dia:",
    "Mahazo taratasim-pilazam-baobao isam-bolana, ary ataony tonga any amin’ny Vondrona Madinika misy azy io",
    "Mahazo tapakila fandoavana ny fambolena nokolokoloiny",
    "Mahazo fanampim-piofanana",
    "Mizara fampiharana tsara sy vaovao amin’ny mpikambana ao amin’ny Vondrona Madinika misy azy",
  ],
  c205: [
    "FAMARITANA VITSIVITSY",
    "Vondrona madinika TERAKA",
    "Araky ny efa nazavaina teo aloha, ny Vondrona Madinikan’ny TERAKA dia fikambanan’ny tantsaha miisa 6 ka hatramin’ny 12 izay mivondrona an-tsitrapo sy ankahalalahana mba hiara hamboly hazo, ho fanatsarana ny tany sy ny vondrom-piarahamonina. Tokony misy fianakaviana 3 samihafa ny ao anatn’ny Vondrona Madinika.",
    "Kilasitera",
    "Araky ny efa nazavaina teo aloha, ny kilasiteran’ny TERAKA dia miisa 30 katramin’ny 50 Vondrona Madinika izay tsy dia mifanalavitra fonenana, manana tany ahafahana mamboly hazo 200.000 (manodidina ny 80 hekitara eo) ary manana pepiniera mba ahazahoana zanakazo.",
    "PROCESSUS DE DEMANDE DE FORMATION ET D'ADHÉSION",
    "Dingana 1 : Tsidiho ny",
    "Ireo tantsaha izay liana amin'ny fidirana ho mpikambana ao amin’ny TERAKA dia tokony hitsidika ny tranonkalan’izy ireo :",
    " ary hampiasa ny ivon-toeram-mpianaran’ny TERAKA.",
    "Dingana 2: Raha azo atao dia manatreha ny fivorian’ny Kilasitera",
    "Tokony hanontany ny manodidina ny tantsaha mba hahafantarana fa misy ny fihaonana amin'ny Kilasiteran’ny TERAKA.",
    "Dingana 3: Mamorona Vondrona Madinika matanjaka ao amin’ny TERAKA",
    "Avereno jerena tsara ny fampiofanana momba ny rafitry ny Vondrona Madinika ao anatin’ny Fampidirana amin’ny andian’ny faha4 amin’ny TERAKA 4 ao amin'ny ivon-toeram-pianarana.",
    "Dingana 4: Fidirana ho mpikambana ao amin’ny TERAKA",
    "Vantany vao nahavita ny fiofanana ny Vondrona Madinika dia hisy ny Kilasitera mpanampy na Mpiahin’ny TERAKA hamita ihany koa:",
    "Ny fangatahana alalana hampiasa ny Ivotoeram-pianarana",
    "Antota-taratasy fidirana ho mpikambana ao amin’ny TERAKA ",
    "Dingana 5: Manokana fotoana hihaonana eo anivon’ny Vondrona Madinika",
    "Ny Vondrona Madinika dia tokony hihaona isan-kerinandro ary handrakitra an-tsoratra ny antsipirian'ny fivoriana, ary, raha azo atao dia manatrika ny fivorian’ny Kilasitera isam-bolana.",
    "Dingana 6: Manapa-kevitra hoe aiza ny hambolena ny hazo",
    "Ny Vondrona Madinika dia tokony hanapa-kevitra hoe aiza no hambolena ny hazon’ny TERAKA.",
    "Dingana 7: Raha voafenonao ny atonta-taratasy rehetra amin’ny fidirana ho mpikambana, dia atero any amin’ny Kilasitera Mpanampy izany.",
    "Raha misy Vondrona Mandinika resy lahatra fa nahafeno soamantsara ny fangatahana hihiditra ho mpikambana ao amin’ny TERAKA dia, afaka mangataka kilasitera mpanampy iray izy ireo mba ho hampiditra azy ho mpikambana anaty Vondrona Madinika an’ny TERAKA.",
    "Dingana 8: Raha voafenonao ny fangatahana ao amin’ny ivon-toeram-pianarana",
    "Ny Mpiahin’ny TERAKA dia hamerina hijery ny antsipirian’ny fangatahana rehetra mba hamaranana ny fangatahana ataon’ny Vondrona Madinika tao amin’ny ivon-toeram-pianarana",
    "BAZELININA",
    "Ny bazelinina dia ny toetran’ny taninjanakazon’ny Vondrona Madinika tamin’ny fahatongavan’ny Vondrona Madinika voaloha tao amin’ny TERAKA.",
    "Iza daholo no tsy maintsy manatrika mandritran'ny fakana ny information fototra",
    "Mandritra ny fitsidihin’ny solon-tenan’ny TERAKA dia tsy maintsy tonga eo avokoa ny Vondrona Madinika rehetra, ary mitondra ilay porofo fananan-tany na fahazahoan-dalana hamboly eo amin’ilay tany.",
    "Inona ny karazana information ilaina amin’ny bazelinina ?",
    "• Hazo bazelinina: ireo hazo efa teo mialohan’ny nidirana ho mpikambana ao amin’ny TERAKA (Tsy tafiditra anatin’ny hazon’ny TERAKA ireo)",
    "• Référence ny tanin-kazo",
    "• Tanin-kazo bazelinina: ny foto-kazo rehetra ao anaty ala, ny fomba nampiasana ny tannin-kazo alohan'ny fambolena hazon’ny TERAKA, ary ny zava-misy hafa momba ny tanin-kazo",
    "• Sarin’ny: a) Vondrona Madinika, b) ny tannin-kazo tsirairay, d) Ny tany nampiharana ny fambolena maharitra; e) ny fatana voatsara ary f) ny pepiniera",
  ],
  c206: [
    "ANDRAIKITRA",
    "Andraikitrin'ny vondrona madinika",
    "Ny Vondrona Madinika dia manaiky ny hamboly hazo 1 000 farafahakeliny isan-taona mandritra ny 5 taona",
    "Ny Vondrona Madinika dia manaiky ny hikolokolo ny hazo ho velona soamantsara mandritra ny 60 taona ary hamerina hamboly ireo izay maty ",
    "Ny Vondrona Madinika dia manaiky handray anjara amin'ny fiofanana omen’nyTERAKA ary hanampy amin'ny famolavolana ny fampiharana tsara",
    "Ny Vondrona Madinika dia manaiky ny hamela ny kilasitera sy Mpanamarina an’ny TERAKA ho tonga eo amin'ny tanin-janakazo handrefesana ny fitomboan'ny hazo",
    "Andraikitrin'ny iTERAKA",
    "Manaiky ny iTERAKA hanome vaovao amin'ny fiofanana, ny fampitam-baovao ary amin'ny Internet",
    "Manaiky ny iTERAKA fa marina sy mangarahara amin’ny fandrefesana",
    "Manaiky ny handoa ny 70% an’ny Vondrona Madinika izay tombony tamin’ny fivarotana ny karbaona ny iTERAKA ary hanao ny fandoavana mialohan’ny hazo velona  izay 6 volana farafahakeliny isan-taona izay mitentina 0,03 euro ny $ 0.03 ( euro )",
    "Manaiky ny iTERAKA hampiasa ezaka tsara indrindra amin’ny fivarotana ny kredi karbaona mba ahazahoana tombony hoan'ny vondrona madinika",
    "FAHAZAHOANA NY FANDOAVANA MIALOHA",
    "Hazo (roa)2 volana",
    "Mba hahafahana mahazo ny fandoavana mialoha...",
    "Hazo (efatra)4 volana",
    "Mba hahafahana mahazo ny fandoavana mialoha...",
    "Hazo (valo ambiny folo)18 volana",
    "Mba hahafahana mahazo ny fandoavam-bola mialoha, dia tsy maintsy ho voaisa ho hazo tao anatin'ny 18 volana ireo hazo",
    "Hazo matotra",
    "Ny fandoavam-bola mialoha ihany koa no ny fandoavam-bola farany ambany izay omen'ny iTERAKA isan-taona ho an'ny hazo velona tsirairay",
    "REPARTITION DES BENEFICES",
    "Ohatra, raha ahazahoana vola 1 000 unit ( unit ) ny fivarotana ny kredi karbaona ",
    "Ary heverinay fa 200 Units ny vidiny, Ny tombony tavela izany dia 800 Units",
    "Ny iTERAKA dia manaiky ny hizara 70%, na 560 Units, miaraka amin'ny vondrona Madinika",
    "Ary 30%, na 240 Units, dia ampidirina ao amin'ny Program TERAKA",
    "Noho izany, amin'ity ohatra ity, ny trosa karbonina amidy amin'ny 1 000 Units dia ahazahoana 560 Units izay miverina amin'ny vondrona madinika",
  ],
  c207: [
    "FAMPITOMBOANA NY TERAKA: AHOANA NO FOMBA FIHITATRY NY TERAKA ?",
    "Dingana 1: Fifandraisana voalohany",
    "Ny tantsaha dia maheno ny fandaharana momba ny TERAKA amin’ny alalan’ny Seminera TERAKA, ny fiofanana ao amin’ny TERAKA, fitsidihana miaraka amin'ny mpikambana iray ao amin'ny TERAKA, fandaharana amin'ny radio, amin’ny alalan’ny gazety na taratasy filazam-baovao, na fomba maro hafa.",
    "Dingana 2: Fampahafatarana sy fandraisana",
    "Raha te hianatra bebe kokoa momba ny TERAKA ny mpamboly iray dia tokony hitsidika ny rohy teraka.org izy ireo ary hanontany ny manodidina mba hahafantarana raha misy ny fihaonan’ny kilasitera an’ny TERAKA ao amin'ny faritra misy azy ireo. Mila miresaka amin'ny tantsaha TERAKA ary mila manatrika ny fivorian’ny kilasitera ( raha azo atao ).",
    "Dingana 3: Fiofanana sy fampiharana",
    "Avereno jerena ny fomba fidirana ao amin’ny TERAKA andian’ny faha 5 “ Ahoana no fomba hidirana ho Vondorona Madinika TERAKA ” mba hahafahana manampy ny tantsaha hanangana Vondrona Madinika matanjaka sy mahazo tsara ny fandaharan’asa ny TERAKA.",
    "Dingana 4: Famoronana Kilasitera",
    "Ny Kilasitera dia fitambaran'ireo Vondrona Madinika 30-50 izay mipetraka eny amin’ny manodidina amin’ny halaviran-toerana vita dia-tongotra. Raha misy Vondrona Madinika latsaky ny 30 ao amin'ny faritra misy anao dia mbola afaka manomboka Kilasitera ianao. Tohizo hatrany ny fampidirana Vondrona Madinika mandra-pahatratrarana ny isa 30 !",
    "Dingana 5: MIHETSIKA ary MANANTANTERAKA",
    "Eto dia efa azo lazaina fa mahafatatra ny tokony ho fantatra ny tantsaha ary tokony hamporisihina HIETSIKA sy HANANTANTERAKA! Tohizo hatrany ny fampiofanana ireo tantsaha TERAKA amin’ny fampiasana ireo fitaovana ao amin'ny Ivon-toeram-pianarana sy amin’ny fizarana ny fomba fiasa tsaran’ny Vondrona Madinika sy ny Kilasitera. Avereno jerena ny fiofanana ao amin'ny ivon-toeram-pianarana momba ny fomba fisoratana anarana ho Vondrona Madinika vantany vao azony ny fandaharana",
    "IREO FOMBA HANDRAISANA MPIKAMBANA HO FANITARANA NY TERAKA",
    "Fifanentanana amin’ny alalan’ny fivoriana eo anivon’ny fiaraha-monina.",
    "Manangana pepiniera maromaro mba ahazahoana zana-kazo maromaro.",
    "Angataho ireo mpikambana ao amin'ny TERAKA hafa manodidina anao mba handray ny mpiray vodirindrina aminy ho mpikambana. ",
    "Mifanentana amin'ny alàlan'ny fiaraha-miasa amin'ireo mpitarika eo an-toerana",
    "Hazavao amin'ny mpikambana ao amin'ny vondrom-piarahamonina ny maha-zava-dehibe ny fambolena hazo sy ny tombontsoa azo amin'ny fandraisana anjara amin'ny TERAKA",
    "Zarao amin'ny olona tsy mpikambana ny taratasy filazam-baovao momban’ny TERAKA",
    "Asehoy ny olona ny tombontsoa toy ny fatana voatsara, ny fandoavam-bola amin’ny hazo, ny hazo fihinam-boa, na ny fambolena maharaitra",
    "Mifanentana amin'ny alalan’ny fiangonana",
    "Fifanentanana amin'ny alalan’ny radio eo an-toerana",
  ],
};
